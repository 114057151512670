import itemData from '../../data/item-data.json';

const DEG_RADIANS_90 = Math.PI / 2;

AFRAME.registerComponent("ui-dynamic", {
    init: function () {
        this.bindMethods();
        const data = this.data;
        const el = this.el;
        this.isShown = false;
        const sceneEl = document.querySelector("a-scene");
        this.cameraRigEl = sceneEl.querySelector("#camera-rig");
        this.cameraEl = this.cameraRigEl.querySelector("#head");
        this.cameraPos = new THREE.Vector3();
        el.setAttribute("visible", false);

        this.createElements();

        this.storeItems = sceneEl.querySelectorAll("a-entity[store-item]");
        for (const item of this.storeItems) {
            item.addEventListener("select", this.onItemSelect);
        }

        // Listen for dismiss click
        this.dismissBtn.addEventListener("click", this.onDismissClick);
    },
    remove: function () {
        // Remove event listeners
        for (const item of this.storeItems) {
            item.removeEventListener("select", this.onItemSelect);
        }
        this.dismissBtn.removeEventListener("click", this.onDismissClick);
    },

    // Listen for item selection
    onItemSelect: function (event) {
        console.log(`Selected item: ${event.detail.itemId}`);
        const item = itemData[event.detail.itemId];
        if (item) {
            this.titleText.setAttribute("value", item.name);
            this.descriptionText.setAttribute("value", item.description);
        } else {
            console.error(`No item data found: ${event.detail.itemId}`);
        }
        this.orientUiToCamera();
        this.show();
    },

    onDismissClick: function () {
        this.hide();
    },

    orientUiToCamera: function () {
        this.cameraPos.setFromMatrixPosition(this.el.sceneEl.camera.matrixWorld);

        let uiRot = this.el.object3D.rotation;
        this.lastCameraRotY =
            this.cameraEl.object3D.rotation.y + this.cameraRigEl.object3D.rotation.y;

        const distance = 1.5;
        let newPosX = this.cameraPos.x + Math.cos(-this.lastCameraRotY - DEG_RADIANS_90) * distance;
        let newPosZ = this.cameraPos.z + Math.sin(-this.lastCameraRotY - DEG_RADIANS_90) * distance;
        this.el.object3D.position.set(newPosX, this.cameraPos.y, newPosZ);
        this.el.object3D.rotation.set(uiRot.x, this.lastCameraRotY, uiRot.z);
    },
    show: function () {
        if (this.isShown == true) {
            return;
        }
        this.el.setAttribute("visible", true);
        this.isShown = true;
    },
    hide: function () {
        if (this.isShown == false) {
            return;
        }
        this.el.setAttribute("visible", false);
        this.isShown = false;
    },

    createElements: function () {
        // Create container plane
        let width = 0.8;
        let height = 1.3;
        const padding = 0.05;
        const top = height / 2 - padding;
        const left = padding;
        const bottom = -height / 2;
        const uiContainer = document.createElement("a-plane");
        uiContainer.setAttribute("material", "shader: flat");
        uiContainer.setAttribute("rotation", "0 0 0");
        uiContainer.setAttribute("color", "#ffffff");
        uiContainer.setAttribute("width", width);
        uiContainer.setAttribute("height", height);
        this.uiContainer = uiContainer;
        this.el.appendChild(this.uiContainer);

        // Create item text
        const deboss = 0.001;
        const titleHeight = 0.1;
        const titleText = document.createElement("a-text-box");
        titleText.setAttribute(
            "value",
            "Item Name"
        );
        titleText.setAttribute("wrap-count", 20);
        titleText.setAttribute("width", width - padding*2);
        titleText.setAttribute("height", titleHeight);
        titleText.setAttribute("position", {
            x: 0, y: top - titleHeight / 2, deboss
        });
        titleText.setAttribute("overlay", "gui");
        this.titleText = titleText;
        uiContainer.appendChild(titleText);

        const descriptionHeight = 0.6;
        const descriptionText = document.createElement("a-text-box");
        descriptionText.setAttribute(
            "value",
            "Item description goes here"
        );
        descriptionText.setAttribute("wrap-count", 30);
        descriptionText.setAttribute("width", width-padding*2);
        descriptionText.setAttribute("height", descriptionHeight);
        descriptionText.setAttribute(
            "position",
            {x: 0, y: top - titleHeight - descriptionHeight/2, z: deboss}
        );
        descriptionText.setAttribute("overlay", "gui");
        this.descriptionText = descriptionText;
        uiContainer.appendChild(descriptionText);

        // Create dismiss button
        const btnHieght = 0.2;
        const dismissBtn = document.createElement("a-entity");
        dismissBtn.setAttribute("id", "dismissButton");
        dismissBtn.setAttribute(
            "ui-button",
            `label: Dismiss; width: ${width}; height: ${btnHieght}}`
        );
        dismissBtn.setAttribute("position", `0 ${bottom + btnHieght / 2} ${deboss}`);
        dismissBtn.setAttribute("overlay", "gui");
        dismissBtn.setAttribute("data-ui-hitbox", true);
        uiContainer.appendChild(dismissBtn);
        this.dismissBtn = dismissBtn;
    },

    /**
     * bind methods used in callbacks
     */
    bindMethods: function () {
        this.onItemSelect = this.onItemSelect.bind(this);
        this.onDismissClick = this.onDismissClick.bind(this);
    },
});
