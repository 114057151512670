{
    "nestrest": {
        "name": "NESTREST",
        "description": "With its specially-designed system of ropes, the NESTREST hanging lounger is easily hung from a tree branch or other suitable support."
    },
    "mini-nestrest": {
        "name": "MINI NESTREST",
        "description": "The Mini Nestrest was inspired by the shapes, textures and colors of nature and by the complex nesting structures of the African weaverbirds."
    },
    "hanging-nestrest": {
        "name": "SWINGREST",
        "description": "While it shares half of its name and much of its basket-like structure with Nestrest, this is in a class all its own. From its rotating tabletop to its elegant suspension system, SWINGREST is the ultimate hanging sofa, perfect for suspending on a terrace or veranda, or even indoors."
    },
    "lounge-chair": {
        "name": "LOUNGE CHAIR",
        "description": "Takes inspiration from the artisanship and improvised seating arrangements of the developing world. Surface and structure merge in this versatile collection, each piece starting as a powder-coated frame of aluminum mesh through which master weavers thread colorful strands of our revolutionary new ecological fiber."
    },
    "side-table": {
        "name": "SIDE TABLE",
        "description": "Collection of sculptural side tables as practical and versatile as they are delightful to the eye. Named after the beloved Italian mushrooms they casually resemble, the tables come in three heights, two base styles and two elegant colorways."
    },
    "bench": {
        "name": "BENCH",
        "description": "Constructed from premium teak, this bench fits perfectly with other pieces from the collection but also stands on its own."
    },
    "sofa": {
        "name": "SOFA",
        "description": "A luxurious lounge collection with an easy-chic spirit. Designed to be elegant, comfortable and appropriate in any season or latitude, this compact system is equally at home on the terrace of an African lodge or the deck of a yacht in the Scandinavian fjords."
    },
    "coffee-table": {
        "name": "COFFEE TABLE",
        "description": "One of the collection‘s signature pieces, the teak coffee table is made with solid premium teak featuring stainless steel feet."
    }
}